import { useEffect, useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import { gsap } from 'gsap';

// components
import Overlay from '../Overlay';
import Button from '../Button';

// styles
import { StyledModal } from './ModalStyles';

const Modal = ({ children, heading, show, hide }) => {
  // for contact form animation
  const modalRef = useRef();
  useEffect(() => {
    show &&
      gsap.from(modalRef.current, {
        y: -25,
        // ease: 'power3.easeOut',
        ease: 'back.out(1)',
        delay: 0.3
      });
  }, [show]);

  return (
    <>
      <Overlay showOverlay={show} hideOverlay={hide} zIndex={103} />
      <StyledModal show={show} ref={modalRef}>
        <Button handleClick={() => hide(false)} ariaLabel="Close modal">
          <FaTimes />
        </Button>

        <h3>{heading}</h3>
        {children}
      </StyledModal>
    </>
  );
};

export default Modal;
