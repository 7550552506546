import { useState, useEffect } from 'react';

// custom hook
import useToggleState from './hooks/useToggleState';

// styles using styled components
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './styles config/globalStyles';
import * as theme from './styles config/theme';
import { screen } from './styles config/utilities';
import { HamburgerButton, StyledApp } from './AppStyles';

// icons
import { IconContext } from 'react-icons';

// components
import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './sections/Hero';
import About from './sections/About';
import Projects from './sections/Projects';
import Contact from './sections/Contact';
import Overlay from './components/Overlay';

const light = {
  bgColor: theme.white_1_rgb,
  headingColor: theme.black_1_rgb,
  textColor: theme.black_2_rgb,
  shadowColor: theme.shadowLight_rgb
};

const dark = {
  bgColor: theme.black_2_rgb,
  headingColor: theme.white_1_rgb,
  textColor: theme.white_2_rgb,
  shadowColor: theme.shadowDark_rgb
};

const maxWidth = screen.sm;
let maxWidthQuery = window.matchMedia(`(max-width: ${maxWidth})`);

function App() {
  const [darkMode, setDarkMode] = useToggleState(false);
  const [colors, setColors] = useState(light);

  const [showOverlay, setShowOverlay] = useState(false);
  const [isMobile, setIsMobile] = useState(maxWidthQuery.matches);

  // set theme colors
  useEffect(() => {
    darkMode ? setColors(dark) : setColors(light);
  }, [darkMode]);

  // dynamically show hamburger icon when screen size change
  useEffect(() => {
    const handler = e => setIsMobile(e.matches);
    maxWidthQuery.addEventListener('change', handler);

    showOverlay ? setShowOverlay(true) : setShowOverlay(false);
    !isMobile && setShowOverlay(false);
  }, [showOverlay, isMobile]);

  // show/hide nav on mobile
  const toggleNav = () => {
    setShowOverlay(!showOverlay);
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledApp>
        <GlobalStyle
          bgColor={colors.bgColor}
          headingColor={colors.headingColor}
          textColor={colors.textColor}
          shadowColor={colors.shadowColor}
        />

        <HamburgerButton
          showOverlay={showOverlay}
          isMobile={isMobile}
          onClick={toggleNav}
          aria-label="Toggle nav"
        >
          <span className="bar bar-top"></span>
          <span className="bar bar-middle"></span>
          <span className="bar bar-bottom"></span>
        </HamburgerButton>

        <Overlay showOverlay={showOverlay} hideOverlay={setShowOverlay} />

        <Header
          darkMode={darkMode}
          toggleTheme={setDarkMode}
          toggleNav={toggleNav}
          showOverlay={showOverlay}
        />

        <main>
          <IconContext.Provider
            value={{
              color: 'currentColor',
              className: 'icon',
              size: '1.5em'
            }}
          >
            <Hero />
            <About />
            <Projects />
            <Contact />
          </IconContext.Provider>
        </main>

        <Footer />
      </StyledApp>
    </ThemeProvider>
  );
}

export default App;
