import React from 'react';

import { StyledContainer } from './ContainerStyles';

const Container = ({ children, width, maxWidth }) => {
  return (
    <StyledContainer width={width} maxWidth={maxWidth}>
      {children}
    </StyledContainer>
  );
};

export default Container;
