import styled from 'styled-components';
import { StyledButton } from '../../../components/Button/ButtonStyles';

export const StyledForm = styled.form`
  position: relative;

  label {
    font-size: 0.7em;
    margin-left: 0.5em;
    padding: 0 0.25em;
    background-color: var(--bgColor);
    transform: translateY(50%);
    display: inline-block;
    /* z-index: 5; */
  }

  input,
  textarea {
    display: block;
    width: 100%;
    margin: 0 auto;
    font-family: inherit;
    font-size: 0.9em;
    color: inherit;
    padding: 0.5em 1em;
    background-color: transparent;
    border: 1px solid var(--textColor);
    border-radius: 3px;

    &:focus {
      border: 1px solid var(--textColor);
      box-shadow: 3px 3px 0.1em 0 var(--accentRed);
    }

    &::placeholder {
      font-size: 0.75em;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: rgba(var(--textColorRGB), 0.5);
    }
  }

  /* show error message */
  .form-group {
    margin-bottom: var(--spacing);
    margin-bottom: calc(var(--spacing) / 2);

    &.error {
      position: relative;

      &::after {
        position: absolute;
        content: attr(data-error);
        color: red;
        font-weight: 700;
        /* bottom: -1.5em; */
        top: 50%;
        transform: translateY(50%);
        right: 1em;
        font-size: 0.5em;
        font-weight: unset;
      }

      /* textarea error message */
      &:last-of-type {
        &::after {
          top: 3em;
        }
      }

      label {
        color: red;
      }

      input,
      textarea {
        border: 1px solid red;
        /* box-shadow: 0 0 0 0.05em red; */
      }
    }
  }
`;

export const SubmitButton = styled(StyledButton)`
  width: 100%;
  padding: 0.5em 1em;
  border-radius: 3px;
  background-color: var(--textColor);
  color: var(--bgColor);
  font-weight: 700;
  margin-top: 1em;
  transform: scale(1);
  transition: background-color 0.2s ease, border 0.1s ease, color 0.2s ease;

  &:hover,
  &:focus {
    outline: none;
    background-color: var(--accentRed);
    transition: background-color 0.1s ease, border 0.1s ease;
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: rgba(var(--textColorRGB), 0.75);
    color: rgba(var(--bgColorRGB), 0.75);
    transition: background-color 0.2s ease, color 0.2s ease;
  }
`;
