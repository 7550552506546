import { createGlobalStyle } from 'styled-components';
import { screen } from '../styles config/utilities';
import {
  accentRed,
  accentRedRGB,
  accentYellow,
  mainFont,
  headingFont,
  bigFont,
  white_1,
  black_1
} from './theme';

export const GlobalStyle = createGlobalStyle`
  :root {
    /* RGB values */
    --bgColorRGB: ${props => props.bgColor} ;
    --headingColorRGB: ${props => props.headingColor};
    --textColorRGB: ${props => props.textColor};

    /* RGB format */
    --bgColor: rgb(var(--bgColorRGB)) ;
    --headingColor: rgb(var(--headingColorRGB));
    --textColor: rgb(var(--textColorRGB));

    /* Hex format */
    --white_1: ${white_1};
    --black_1: ${black_1};

    /* accent colors */
    --accentRed: ${accentRed};
    --accentRedRGB: ${accentRedRGB};
    --accentYellow: ${accentYellow};

    /* fonts */
    --mainFont: ${mainFont};
    --headingFont: ${headingFont};
    --bigFont: ${bigFont};

    /* utilities */
    --shadow: ${props => props.shadowColor};
    --spacing: 0.75em;
  }

  * {
    margin:0;
    padding:0;
    box-sizing:inherit;

    // custom scrollbar for Firefox
    scrollbar-width: thin;
    scrollbar-color: var(--accentRed) var(--bgColor);

    // custom scrollbar for Chrome-like browsers (Chrome, Edge, Safari, etc)
    &::-webkit-scrollbar{
      width: 0.5vw;

      @media only screen and (max-width: ${screen.lg}) {
        width: 0.6vw;
      }

      @media only screen and (max-width: ${screen.md_2}) {
        width: 0.75vw;
      }

      @media only screen and (max-width: ${screen.sm}) {
        width: 1vw;
      }

      /* background */
      &-track {
        background-color: var(--bgColor);
      }

      /* moving element */
      &-thumb {
        background-color: var(--accentRed);
        border-radius: 2px;
      }
    }

    &:focus {
      outline:none;
    }
  }

  html {
    font-size: 62.5%; 
    box-sizing: border-box;
    /* scroll-behavior: smooth; */
  }

  body {
    font-size: clamp(1.6rem, 3vw, 1.9rem);
    font-family: var(--mainFont);
  }

  .logo-front {
    fill: var(--textColor);
  }

  /* NOTE: set focus/hover state on parent element to show styles on tab */
  .hover-underline {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0.08em;
      width: 75%;
      background-color: var(--accentRed);
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.2s ease-in-out;
    }
  }

  ::selection{
    color: var(--white_1);
    background-color: var(--accentRed);
  }
`;
