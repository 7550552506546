import {
  SiHtml5,
  SiCss3,
  SiSass,
  SiJavascript,
  SiReact,
  SiVisualstudiocode,
  SiGit,
  SiGithub,
  SiNpm,
  SiAdobexd,
  SiFigma,
  // SiTrello,
  SiNetlify
  // SiNotion,
  // SiLinux
} from 'react-icons/si';
import { FaTerminal } from 'react-icons/fa';

export const devSkills = [
  { label: 'HTML', icon: <SiHtml5 /> },
  { label: 'CSS', icon: <SiCss3 /> },
  { label: 'Sass', icon: <SiSass /> },
  { label: 'JavaScript', icon: <SiJavascript /> },
  { label: 'React', icon: <SiReact /> }
];

export const toolsSkills = [
  { label: 'VS Code', icon: <SiVisualstudiocode /> },
  { label: 'Git', icon: <SiGit /> },
  { label: 'GitHub', icon: <SiGithub /> },
  { label: 'NPM', icon: <SiNpm /> },
  { label: 'Netlify', icon: <SiNetlify /> },
  { label: 'Adobe XD', icon: <SiAdobexd /> },
  { label: 'Figma', icon: <SiFigma /> },
  // { label: 'Trello', icon: <SiTrello /> },
  // { label: 'Notion', icon: <SiNotion /> },
  // { label: 'Linux', icon: <SiLinux /> },
  { label: 'Terminal', icon: <FaTerminal /> }
];
