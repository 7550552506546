import React from 'react';

// styles
import { Label, StyledHeading } from './SectionHeadingStyles';

// icons
import { HiEmojiHappy } from 'react-icons/hi';

const SectionHeading = ({ label, text, trigRef }) => {
  return (
    <StyledHeading data-text={text} ref={trigRef}>
      <Label>
        <HiEmojiHappy className="emoji" />
        <span>{label}</span>
      </Label>
    </StyledHeading>
  );
};

export default SectionHeading;
