import { useRef } from 'react';

// custom hooks
import useScrollAnim from '../../hooks/useScrollAnim';

// components
import Container from '../../components/Container';
import SectionHeading from '../../components/SectionHeading';
import Image from '../../components/Image';
import Heading from '../../components/Heading';
import { devSkills, toolsSkills } from './skillsList';

// styles
import { StyledAbout, AboutMe, Skills } from './AboutStyles';
import { screen } from '../../styles config/utilities';

// assets
import photoPNG from '../../assets/images/mracho.png';
import photoWebP from '../../assets/images/mracho.webp';

const About = () => {
  const displayList = arr => {
    return arr.map(skill => (
      <li key={skill.label}>
        {skill.icon}
        {skill.label}
      </li>
    ));
  };

  // for scroll animation
  const aboutRef = useRef();
  const aboutTriggerRef = useRef();
  useScrollAnim(aboutRef, aboutTriggerRef);

  return (
    <StyledAbout id="about" ref={aboutRef}>
      <SectionHeading
        label="About"
        text="Get to know me"
        trigRef={aboutTriggerRef}
      />
      <Container width="90%" maxWidth={screen.md_2}>
        <Heading>Hi there!</Heading>
        <AboutMe>
          <Image
            srcWebP={photoWebP}
            srcPNG={photoPNG}
            className="photo"
            alt="Michelle Racho"
            height="160"
            width="160"
          />
          <div className="text-container">
            <p>
              My name is <span className="name">Michelle Angela Racho</span> and
              I'm a software engineer based in{' '}
              <span className="location">Evans, GA</span>.
            </p>
            <p>
              I enjoy working in teams and I also work well independently. I am
              passionate about learning new things and technologies that will
              expand my knowledge within all aspects of Computer Science
              especially in front-end development and design.
            </p>
            <p>
              My personal mission is to further my specialization in front-end
              development, work with amazing people to help develop cool and
              useful products and one day be able to mentor new developers. I am
              constantly pushing myself out of my comfort zone to learn more and
              expand my skill set.
            </p>
          </div>
        </AboutMe>

        <Heading>My toolbox:</Heading>
        <Skills>
          <ul>{displayList(devSkills)}</ul>
          <ul>{displayList(toolsSkills)}</ul>
        </Skills>
      </Container>
    </StyledAbout>
  );
};

export default About;
