import styled, { css } from 'styled-components';
import {
  flexCenter,
  screen,
  messageBubble
} from '../../styles config/utilities';

export const StyledLink = styled.a`
  ${flexCenter('row')}

  ${({ type }) =>
    type === 'email' &&
    css`
      display: inline;
      font-weight: 700;
    `};

  font-size: clamp(
    1.4rem,
    5vw,
    ${({ fontSize }) => (fontSize ? `${fontSize}` : '1em')}
  );

  color: var(--textColor);
  text-decoration: none;
  position: relative;
  transition: color 0.2s ease;

  ${({ msgTop, msgLeft, type }) =>
    type === 'email'
      ? messageBubble('data-label', `${msgTop}`, `${msgLeft}`, false, '3deg')
      : messageBubble('data-label', `${msgTop}`, `${msgLeft}`, false)}

  .icon {
    margin-right: 0.3em;
    opacity: 1;

    ${({ iconOnly }) =>
      iconOnly
        ? css`
            opacity: 1;
            transition: filter 0.15s ease;
          `
        : css`
            font-size: 0.75em;
            /* margin-left: 0.25em; */
            /* opacity: 0; */
            transition: opacity 0.3s ease 0.15s;
            /* transition: opacity 0.2s ease-in-out; */

            @media only screen and (max-width: ${screen.md}) {
              opacity: 1;
            }
          `}
  }

  .hover-underline {
    /* transform: translateX(-35%); */
    /* transition: transform 0.2s ease-in-out; */

    @media only screen and (max-width: ${screen.md}) {
      transform: translateX(0);
    }
  }

  @media (hover: hover) {
    &:hover,
    &:focus {
      ${({ iconOnly }) =>
        iconOnly
          ? css`
              /* color: var(--accentRed); */

              .icon {
                filter: drop-shadow(0.15em 0.11em 0 var(--accentRed));
                transition: filter 0.15s ease;
              }
            `
          : css`
              filter: drop-shadow(0.15rem 0 0 var(--accentRed));
            `}

      .icon {
        opacity: 1;
      }

      .hover-underline {
        transform: translateX(0);

        /* &::after {
          transform: ${({ type }) =>
          type === 'email' ? 'scale(1)' : 'scaleX(0.7)'};
        } */
        &::after {
          transform: scaleX(1);
        }
      }
    }
  }
`;
