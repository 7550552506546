import styled from 'styled-components';
import { screen, flexCenter } from '../../styles config/utilities';

export const StyledHeader = styled.header`
  /* mobile, display as side drawer */
  ${flexCenter('column')}
  justify-content: space-between;

  background-color: var(--bgColor);
  font-size: 2.4rem;
  padding: 5em 0;

  position: fixed;
  height: 100vh;
  width: min(100%, 280px);
  right: 0;
  box-shadow: 0 1.5em 3.8em rgba(0, 0, 0, 0.3);
  transform: ${({ show }) => (show ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.4s ease 0.25s;
  z-index: 101;

  /* non-mobile, position on top */
  @media only screen and (min-width: ${screen.sm}) {
    background-color: transparent;
    flex-direction: row;
    padding: 0.5em 0;

    position: absolute;
    width: 100%;
    height: auto;
    box-shadow: none;
    transform: unset;
    transition: none;
  }

  /* logo */
  & > a {
    svg {
      height: 1em;
      width: auto;
      display: block;
    }

    @media (hover: hover) {
      &:focus {
        outline: 1px solid var(--textColor);
        outline-offset: 0.15em;
      }
    }
  }
`;

export const DarkModeButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  height: 3rem;

  .sun,
  .moon {
    height: 75%;
    width: auto;
    display: block;
    fill: var(--textColor);
    transition: background-color 0.2s ease-out, fill 0.2s ease-out,
      border 0.1s ease-out;
    border-radius: 50%;

    @media only screen and (max-width: ${screen.sm}) {
      height: 100%;
    }
  }

  @media (hover: hover) {
    &:focus,
    &:hover {
      .sun,
      .moon {
        background-color: var(--accentYellow);
        fill: var(--black_1);
        border: 0.15rem solid var(--accentYellow);
        transition: background-color 0.2s ease-out, fill 0.2s ease-out,
          border 0.1s ease-out;
      }
    }
  }
`;
