import styled from 'styled-components';
import { flexCenter, screen } from '../../styles config/utilities';

export const StyledAbout = styled.section`
  position: relative;
  padding-top: 5rem;

  h3 {
    margin-top: 3rem;
  }
`;

export const AboutMe = styled.div`
  ${flexCenter('column')}

  @media only screen and (min-width: ${screen.md}) {
    flex-direction: row;
  }

  .text-container {
    flex: 1;

    p {
      line-height: 1.5;

      &:not(:last-of-type) {
        margin-bottom: var(--spacing);
      }

      .location {
        white-space: nowrap;
      }
    }
  }

  .name {
    font-weight: bolder;
    color: var(--headingColor);
    letter-spacing: 0.3px;
  }

  .photo {
    margin: 3rem 0;
    max-width: 16rem;

    @media only screen and (min-width: ${screen.md}) {
      margin: 0 3rem 0 0;
    }
  }
`;

export const Skills = styled.div`
  ${flexCenter('column')}

  ul {
    ${flexCenter('row wrap')}
    list-style-type: none;
    margin: var(--spacing) auto 0;

    @media only screen and (max-width: ${screen.sm}) {
      &:first-of-type {
        margin-top: 1.5em;
      }
    }
  }

  li {
    ${flexCenter('column')}
    font-size: 0.65em;
    font-size: clamp(1.2rem, 5vw, 0.65em);
    margin: 0 0.75em var(--spacing);

    .icon {
      font-size: 1.2em;
      margin-bottom: 0.5em;
      filter: drop-shadow(0.1em 0.1em 0 var(--accentRed));
    }
  }
`;
