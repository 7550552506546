// Breakpoints
export const screen = {
  xs: '515px',
  sm: '700px',
  md: '768px',
  md_2: '1000px',
  lg: '1440px',
  xl: '1920px'
};

// Mixins
export const flexCenter = flow => `
  display: flex;
  flex-flow: ${flow};
  align-items: center;
  justify-content: center;
`;

// add message bubble on element::before
export const messageBubble = (
  content,
  top,
  left,
  showFirst,
  rotate = '7deg'
) => `
  &::before {
    content: attr(${content});
    font-size: clamp(1.1rem, 5vw, 0.63em);
    font-weight: 400;
    color: var(--white_1);
    text-align: center;
    background-color: var(--accentRed);
    padding: 0.25em 0.5em 1em;

    position: absolute;
    left: ${left};
    top: ${top};
    transform: translateX(-25%) rotate(${rotate});
    transition: opacity 0.2s ease;
    opacity: ${showFirst ? 1 : 0};
    pointer-events: none;
    z-index: 1;
    white-space: nowrap;

    clip-path: polygon(
      0 0,
      100% 0,
      100% 75%,
      45% 75%,
      35% 100%,
      35% 75%,
      0 75%
    );

    /* hide/remove styles on small screens */
    @media only screen and (max-width: ${screen.md}) { 
      all: unset;
    } 
  }

  @media (hover: hover){
    &:hover, 
    &:focus {
      &::before {
        opacity: ${showFirst ? 0 : 1};
        visibility: ${showFirst ? 'hidden' : 'visible'};
      }
    }
  }
`;
