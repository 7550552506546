import {
  SiGithub,
  SiLinkedin,
  SiTwitter,
  SiCodepen,
  SiAngellist
} from 'react-icons/si';

export const links = [
  {
    label: 'GitHub',
    url: 'https://github.com/michelleracho',
    icon: <SiGithub />
  },
  {
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/in/michelleracho',
    icon: <SiLinkedin />
  },
  {
    label: 'Twitter',
    url: 'https://twitter.com/michelleracho_',
    icon: <SiTwitter />
  },
  {
    label: 'CodePen',
    url: 'https://codepen.io/michelleracho',
    icon: <SiCodepen />
  },
  {
    label: 'AngelList',
    url: 'https://angel.co/u/michelleracho',
    icon: <SiAngellist />
  }
];
