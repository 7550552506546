import styled from 'styled-components';
import {
  flexCenter,
  screen,
  messageBubble
} from '../../styles config/utilities';

export const StyledProjects = styled.section`
  position: relative;
  padding-top: 5rem;

  /* container */
  & > div {
    min-height: 75vh;
    ${flexCenter('column')}
    align-items: flex-start;

    @media only screen and (max-width: ${screen.md}) {
      flex-direction: row;
    }
  }

  ul {
    list-style-type: none;
    z-index: 1;
  }

  .message-bubble {
    ${messageBubble('data-hover', '30%', '50%', true)}
  }

  /* temp - remove if projects are done */
  .temp.message-bubble {
    ${messageBubble('data-text', '35%', '25%', true, '-7deg')}
    ${messageBubble('data-text', '35%', '40%', true, '0deg')}
    font-size: 2.5em;

    &::before {
      clip-path: polygon(
        0 0,
        100% 0,
        100% 75%,
        25% 75%,
        30% 100%,
        30% 75%,
        0 75%
      );

      @media only screen and (max-width: ${screen.md}) {
        content: attr(data-text);
        font-size: clamp(1.1rem, 5vw, 0.63em);
        font-weight: 400;
        color: var(--white_1);
        text-align: center;
        background-color: var(--accentRed);
        padding: 0.25em 0.5em 1em;

        position: absolute;
        left: 25%;
        top: 35%;
        transform: translateX(-25%) rotate(0);
        transition: opacity 0.2s ease;
        opacity: 1;
        pointer-events: none;
        z-index: 1;
        white-space: nowrap;

        clip-path: polygon(
          0 0,
          100% 0,
          100% 75%,
          25% 75%,
          30% 100%,
          30% 75%,
          0 75%
        );
      }
    }
    /* temp - remove if projects are done */
  }

  .emoji-bg {
    visibility: visible;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 30em; // 50%;
    width: 30em; // auto;
    transform: rotate(-12deg);
    z-index: 0;

    /* uncomment if projects are done */
    /* @media only screen and (max-width: ${screen.md}) {
      visibility: hidden;
    } */

    /* temp - remove if projects are done */
    bottom: 5%;
    right: 50%;
    height: 50%;
    width: auto;
    transform: translateX(50%) rotate(0deg);

    @media only screen and (max-width: ${screen.md}) {
      height: auto;
      width: 75%;
    }
    @media only screen and (max-width: ${screen.sm}) {
      width: 80%;
      bottom: 20%;
    }
    /* temp - remove if projects are done */
  }

  .emoji-logo {
    fill: rgba(var(--headingColorRGB), 0.05);
  }

  .hand-fill {
    fill: var(--textColor);
  }

  .hand-outline {
    fill: var(--bgColor);
  }
`;

export const Temp = styled.div`
  font-size: 2.5em;
  color: var(--bgColor);
  background-color: var(--textColor);
  transform: rotate(25%);
  padding: 0 0.5em;
`;
