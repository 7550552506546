import React, { useState, useEffect } from 'react';

// components
import Modal from '../../../components/Modal';

// styles
import { SubmitButton, StyledForm } from './ContactFormStyles';

// constants
const DEFAULT_SUBJECT = 'Hi from portfolio visitor';

const ContactForm = ({ showForm, setShowForm }) => {
  // input fields
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [subject, setSubject] = useState(DEFAULT_SUBJECT);
  const [message, setMessage] = useState('');

  // for submit button
  const [isFilled, setIsFilled] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);

  // submit status
  const [submitStatus, setSubmitStatus] = useState(false);
  const [buttonText, setButtonText] = useState('');

  // enable submit button if all inputs are valid, company is optional
  useEffect(() => {
    const isFilledArr = Object.values(isFilled);
    const isAllTrue = isFilledArr.every(item => item === true);

    if (
      (message !== '' &&
        company === '' &&
        isFilledArr.length >= 3 &&
        isAllTrue) ||
      (message !== '' && company !== '' && isFilledArr.length >= 4 && isAllTrue)
    ) {
      setIsDisabled(false);
      setButtonText('Send');
    } else {
      setIsDisabled(true);
      setButtonText('Please fill out the form');
    }
  }, [message, company, isFilled]);

  // check if email matches abc@email.co OR abc@email.com pattern
  const emailPattern = email => {
    const pattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    return pattern.test(email.toLowerCase());
  };

  const validateEmail = () => {
    const isValidEmail = emailPattern(email);

    if (email !== '' && !isValidEmail) {
      return false;
    }

    return true;
  };

  // check input values after input field is not focused (onBlur)
  const checkInput = e => {
    const target = e.target;
    const parentDiv = target.parentElement;
    const validEmail = validateEmail();

    // add error highlights if input is empty
    // company and subject are optional
    if (
      target.value === '' &&
      target.name !== 'company' &&
      target.name !== 'subject'
    ) {
      parentDiv.classList.add('error');
      parentDiv.setAttribute(
        'data-error',
        `${target.name.toUpperCase()} is required`
      );
      // add/update {name: false} to isFilled
      setIsFilled({ ...isFilled, [target.name]: false });
    }
    // add error highlights if email is not valid
    else if (target.name === 'email' && !validEmail) {
      parentDiv.classList.add('error');
      parentDiv.setAttribute(
        'data-error',
        'Please enter a valid email address'
      );
      setIsFilled({ ...isFilled, [target.name]: false });
    }
    // remove error highlights
    else {
      parentDiv.classList.remove('error');
      // add/update {name: true} to isFilled
      setIsFilled({ ...isFilled, [target.name]: true });
    }
  };

  // remove error styles on input focus
  const handleFocus = e => {
    e.target.parentElement.classList.remove('error');
  };

  // required to handle netlify form
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  // submit form and reset state
  const handleSubmit = e => {
    // required to handle netlify form
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact-form',
        name,
        email,
        company,
        subject,
        message
      })
    }).then(() => {
      setSubmitStatus(true);
      setIsDisabled(true);
      setButtonText('Message sent!');
    });

    e.preventDefault();
  };

  const handleModalClose = e => {
    setShowForm(false);
    setSubmitStatus(false);

    // add delay before clearing the input fields
    setTimeout(() => {
      setName('');
      setEmail('');
      setCompany('');
      setSubject('');
      setMessage('');
      setIsDisabled(true);
      setIsFilled({});
    }, 500);
  };

  return (
    <Modal heading="Send me a Message!" show={showForm} hide={handleModalClose}>
      <StyledForm onSubmit={handleSubmit}>
        <input type="hidden" name="form-name" value="contact-form" />
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={e => {
              setName(e.target.value);
              checkInput(e);
            }}
            // onChange={e => setName(e.target.value)}
            onBlur={checkInput}
            onFocus={handleFocus}
            disabled={submitStatus}
          />
        </div>

        {/* BUG if onChange w/ checkInput, submit button will be enabled when 1 char is entered but will not submit form */}
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            id="email"
            name="email"
            value={email}
            // onChange={e => {
            //   setEmail(e.target.value);
            //   checkInput(e);
            // }}
            onChange={e => setEmail(e.target.value)}
            onBlur={checkInput}
            onFocus={handleFocus}
            disabled={submitStatus}
          />
        </div>
        <div className="form-group">
          <label htmlFor="company">Company</label>
          <input
            type="text"
            id="company"
            name="company"
            value={company}
            onChange={e => {
              setCompany(e.target.value);
              checkInput(e);
            }}
            // onChange={e => setCompany(e.target.value)}
            onBlur={checkInput}
            onFocus={handleFocus}
            placeholder="Optional"
            disabled={submitStatus}
          />
        </div>
        <div className="form-group">
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={subject === DEFAULT_SUBJECT ? '' : subject}
            onChange={e => {
              setSubject(e.target.value);
              checkInput(e);
            }}
            // onChange={e => setSubject(e.target.value)}
            onBlur={checkInput}
            onFocus={handleFocus}
            placeholder={DEFAULT_SUBJECT}
            disabled={submitStatus}
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            name="message"
            id="message"
            rows="3"
            value={message}
            onChange={e => {
              setMessage(e.target.value);
              checkInput(e);
            }}
            // onChange={e => setMessage(e.target.value)}
            onBlur={checkInput}
            onFocus={handleFocus}
            disabled={submitStatus}
          ></textarea>
        </div>

        <SubmitButton type="submit" disabled={isDisabled}>
          {buttonText}
        </SubmitButton>
      </StyledForm>
    </Modal>
  );
};

export default ContactForm;
