import React from 'react';

// styles
import { StyledLink } from './LinkStyles';

const Link = ({
  url,
  label,
  ariaLabel,
  dataLabel,
  icon,
  type,
  fontSize,
  msgTop,
  msgLeft
}) => {
  const hasLabel = label && <span className="hover-underline">{label}</span>;

  return (
    <StyledLink
      href={url}
      target="_blank"
      rel="noreferrer noopener"
      aria-label={ariaLabel}
      data-label={dataLabel}
      type={type}
      fontSize={fontSize}
      icon={icon}
      msgTop={msgTop}
      msgLeft={msgLeft}
      iconOnly={hasLabel ? false : true}
    >
      {icon}
      {hasLabel}
    </StyledLink>
  );
};

export default Link;
