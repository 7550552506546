import styled from 'styled-components';
import { screen } from './styles config/utilities';

export const StyledApp = styled.div`
  color: var(--textColor);
  background-color: var(--bgColor);
  transition: color 0.3s ease, background-color 0.4s ease-out;

  position: relative;
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr min(90%, ${screen.xl}) 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    '. header .'
    '. main .'
    '. footer .';

  header {
    grid-area: header;
  }

  main {
    grid-area: main;
  }

  footer {
    grid-area: footer;
  }
`;

export const HamburgerButton = styled.button`
  display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};
  cursor: pointer;
  background-color: transparent;
  border: none;
  position: fixed;
  top: 2em;
  right: 5%;
  z-index: 102;

  height: 1.8em;
  width: 2.2em;

  .bar {
    background-color: var(--textColor);
    width: 100%;
    height: 4px;
    position: absolute;
    left: 0;
    transition: transform 0.2s ease-out;

    &-top {
      top: 0;
      ${({ showOverlay }) => showOverlay && 'transform: translateY(250%);'}
    }

    &-middle {
      transform: translateY(-50%);
    }

    &-bottom {
      bottom: 0;
      ${({ showOverlay }) => showOverlay && 'transform: translateY(-250%);'}
    }
  }
`;
