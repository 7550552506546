import styled from 'styled-components';

export const StyledButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  font-family: inherit;

  &:focus {
    outline: 1px solid var(--textColor);
    outline-offset: 2px;
  }

  .icon {
    display: block;
  }
`;
