import styled from 'styled-components';
import { screen, flexCenter } from '../../styles config/utilities';

export const StyledHero = styled.section`
  ${flexCenter('column')}
  align-items:flex-start;
  height: 100vh;
  position: relative;

  * {
    @media only screen and (max-width: ${screen.sm}) {
      margin-top: -3em;
    }
  }

  h1 {
    font-size: clamp(6rem, 10vw, 12rem);
    font-family: var(--headingFont);
    color: var(--headingColor);
  }

  p {
    font-size: clamp(1.9rem, 3vw, 2.34rem);
    margin-top: var(--spacing);
    width: min(75%, calc(${screen.sm}));
  }

  /* svg bg img */
  .hero-bg {
    position: absolute;
    height: 100%;
    width: auto;
    right: 0;
    top: 0;
    margin-top: unset;
    user-select: none;

    @media only screen and (max-width: ${screen.sm}) {
      height: 65%;
      top: 3em;
    }
  }

  .name {
    fill: rgba(var(--headingColorRGB), 0.05);
  }

  .hand-fill {
    fill: var(--textColor);
  }

  .hand-outline {
    fill: var(--bgColor);
  }
`;

export const Scroll = styled.div`
  background: linear-gradient(
    to bottom,
    var(--textColor) 80%,
    var(--bgColor) 95%,
    var(--bgColor)
  );
  height: 25%;
  width: 0.2em;
  position: absolute;
  bottom: 1.5%;
  left: 10%;

  &::before {
    position: absolute;
    content: 'Scroll';
    background-color: var(--bgColor);
    right: 0;
    bottom: 25%;
    transform: translateX(45%) rotate(-90deg);
    font-size: 0.6em;
    padding: 0.5rem;
  }

  @media only screen and (max-width: ${screen.sm}) {
    bottom: 7.5%;
  }
`;
