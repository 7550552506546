import { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// custom hook for scroll animation using GSAP ScrollTrigger plugin

const useScrollAnim = (animRef, trigRef) => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.from(animRef.current, {
      y: 100,
      opacity: 0,
      ease: 'power3.easeOut',
      scrollTrigger: {
        trigger: trigRef.current,
        start: 'top 90%',
        end: 'bottom 60%'
        // markers: true
        // toggleActions: 'restart complete reverse reset'
      }
    });
  }, [animRef, trigRef]);
};

export default useScrollAnim;
