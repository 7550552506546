import React from 'react';

// styles
import { StyledImage } from './ImageStyles';

const Image = ({ srcWebP, srcJPG, srcPNG, className, alt, height, width }) => {
  return (
    <StyledImage>
      <source type="image/webp" srcSet={srcWebP} />
      <source type="image/png" srcSet={srcPNG} />
      <source type="image/jpg" srcSet={srcJPG} />
      <img
        className={className}
        src={srcPNG ? srcPNG : srcJPG}
        alt={alt}
        height={height}
        width={width}
      />
    </StyledImage>
  );
};

export default Image;
