import styled from 'styled-components';

export const StyledFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 1.2rem;
  padding: 0.5em 0;
  position: absolute;
  bottom: 0;
  pointer-events: none;
  user-select: none;

  svg {
    height: 0.75em;
    width: auto;
    margin-left: 0.5em;
  }
`;
