// COLOR Variables
export const white_1 = '#fbfbf2'; // dark mode text heading, light mode bg
export const white_1_rgb = '251, 251, 242'; // rgb(251, 251, 242)

export const white_2 = '#f5f1e3'; // dark mode text body
export const white_2_rgb = '245, 241, 227'; // rgb(245, 241, 227)

export const black_1 = '#050505'; // light mode text heading
export const black_1_rgb = '5, 5, 5'; // rgb(5, 5, 5)

export const black_2 = '#242424'; // light mode text body, dark mode bg
export const black_2_rgb = '36, 36, 36'; // rgb(36, 36, 36)

export const accentRed = '#ed6a5a';
export const accentRedRGB = '237, 106, 90';
// export const accentRedDark = '#bc200d';
export const accentYellow = '#ffd257';

// export const shadow_rgb = '45, 45, 45';
export const shadowDark_rgb = '61, 61, 61';
export const shadowLight_rgb = '36, 36, 36';

// TYPOGRAPHY Variables
export const mainFont = "'Montserrat', sans-serif";
export const headingFont = "'Raleway', sans-serif";
export const bigFont = "'Archivo Black', sans-serif";
