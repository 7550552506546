import { useState, useEffect } from 'react';

const useToggleState = (initialVal = false) => {
  const [state, setState] = useState(initialVal);
  const [isFirstMount, setIsFirstMount] = useState(true);

  const toggle = () => {
    setState(!state);
  };

  useEffect(() => {
    // mimic componentDidUpdate and only run useEffect when component update
    if (isFirstMount) {
      setIsFirstMount(false);
      return;
    }

    setState(initialVal);
  }, [isFirstMount, initialVal]);

  return [state, toggle];
};

export default useToggleState;
