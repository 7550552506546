import styled, { css } from 'styled-components';
import { screen } from '../../styles config/utilities';

export const StyledModal = styled.div`
  position: fixed;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 999;
  background-color: var(--bgColor);
  color: var(--textColor);
  padding: 2em;
  width: min(80%, calc(${screen.xs} - 75px));
  border-radius: 5px;
  box-shadow: 0 0 0.5em 0 var(--bgColor);
  transition: opacity 0.4s ease 0.2s, visibility 0.4s ease 0.2s,
    background-color 0.4s ease-in;

  ${({ show }) =>
    show
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `};

  /* Close modal button */
  button:first-child {
    position: absolute;
    top: var(--spacing);
    right: var(--spacing);
    font-size: 0.85em;
  }
`;
