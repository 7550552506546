import { useState, useRef } from 'react';

// custom hooks
import useScrollAnim from '../../hooks/useScrollAnim';

// components
import Container from '../../components/Container';
import SectionHeading from '../../components/SectionHeading';
import Link from '../../components/Link';
import { links } from './socialsList';
import ContactForm from './ContactForm';

// styles
import { Bar, ModalButton, StyledContact } from './ContactStyles';
import { screen } from '../../styles config/utilities';

const Contact = () => {
  const [showForm, setShowForm] = useState(false);

  const linksDisplay = links.map(link => (
    <li key={link.label}>
      <Link
        url={link.url}
        dataLabel={link.label}
        ariaLabel={`My ${link.label} account`}
        icon={link.icon}
        type="iconOnly"
        msgTop="-125%"
        msgLeft="70%"
      />
    </li>
  ));

  // for scroll animation
  const contactRef = useRef();
  const contactTriggerRef = useRef();
  useScrollAnim(contactRef, contactTriggerRef);

  return (
    <>
      <StyledContact id="contact" ref={contactRef}>
        <SectionHeading
          label="Contact"
          text="Get in touch"
          trigRef={contactTriggerRef}
        />

        <Container width="70%" maxWidth={screen.md}>
          <p>
            Thank you for visiting my page!{' '}
            {/* I am actively searching for my next role.  */} If you would like
            to see my resume, work together or ask questions, please get in
            touch with me{' '}
            <ModalButton
              onClick={() => setShowForm(true)}
              aria-label="Show contact form"
              data-label="Message me!"
              msgTop="-125%"
              msgLeft="70%"
            >
              <span className="hover-underline">here</span>
            </ModalButton>{' '}
            {/* <Link
              url="mailto:michelleangela.racho@gmail.com"
              dataLabel="michelleangela.racho@gmail.com"
              ariaLabel="My personal email"
              type="email"
              msgTop="-120%"
              msgLeft="64%"
              label="here"
            />{' '} */}
            or through any of my online spaces linked below.
          </p>
          <p>Any feedback is also very much appreciated!</p>

          <Bar />

          <ul>{linksDisplay}</ul>
        </Container>
      </StyledContact>

      <ContactForm showForm={showForm} setShowForm={setShowForm} />
    </>
  );
};

export default Contact;
