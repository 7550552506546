import React from 'react';

// styles
import { StyledOverlay } from './OverlayStyles';

const Overlay = ({ showOverlay, hideOverlay, zIndex }) => {
  return (
    <StyledOverlay
      showOverlay={showOverlay}
      onClick={() => hideOverlay(false)}
      zIndex={zIndex}
    />
  );
};

export default Overlay;
