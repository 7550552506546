import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

// styles
import { StyledNav, NavLink } from './NavbarStyles';

const Navbar = ({ hideNav }) => {
  // using GSAP ScrollToPlugin
  // BUG: on first click - goes over/cuts off the section heading
  gsap.registerPlugin(ScrollToPlugin);

  const handleClick = e => {
    gsap.to(window, { duration: 1, scrollTo: e });
    hideNav();
  };

  return (
    <StyledNav>
      <NavLink onClick={handleClick.bind(this, '#about')}>
        <span className="hover-underline">About</span>
      </NavLink>
      <NavLink onClick={handleClick.bind(this, '#projects')}>
        <span className="hover-underline">Projects</span>
      </NavLink>
      <NavLink onClick={handleClick.bind(this, '#contact')}>
        <span className="hover-underline">Contact</span>
      </NavLink>
    </StyledNav>
  );
};

export default Navbar;
