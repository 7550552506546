import styled, { css } from 'styled-components';

export const StyledOverlay = styled.div`
  ${({ showOverlay }) =>
    showOverlay
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}

  transition: opacity 0.4s ease 0.2s, visibility 0.4s ease 0.2s, background-color .4s ease-in;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(var(--shadow), 0.7);
  backdrop-filter: blur(2px);
  z-index: ${({ zIndex }) => (zIndex ? `${zIndex}` : 100)};
`;
