import { useRef } from 'react';

// custom hooks
import useScrollAnim from '../../hooks/useScrollAnim';

// components
import Container from '../../components/Container';
import SectionHeading from '../../components/SectionHeading';
// import Project from './Project';
// import { projects } from './projectsList';

// styles
import { StyledProjects } from './ProjectsStyles';
import { screen } from '../../styles config/utilities';

// assets
import { ReactComponent as BgImg } from '../../assets/logo/emoji-bg.svg';

const Projects = () => {
  // NOTE uncomment if projects are done!
  // const projectsList = projects.map((prj, idx) => (
  //   <Project
  //     key={`${idx}-${prj.title}`}
  //     title={prj.title}
  //     mobileJPG={prj.mobileJPG}
  //     mobileWebP={prj.mobileWebP}
  //     webJPG={prj.webJPG}
  //     webWebP={prj.webWebP}
  //     desc={prj.desc}
  //     tech={prj.tech && prj.tech.join(', ')}
  //     design={prj.design}
  //     previewURL={prj.previewURL}
  //     sourceURL={prj.sourceURL}
  //   />
  // ));

  // for scroll animation
  const projectsRef = useRef();
  const projectsTriggerRef = useRef();
  useScrollAnim(projectsRef, projectsTriggerRef);

  return (
    <StyledProjects id="projects" ref={projectsRef}>
      <SectionHeading
        label="Projects"
        text="Some of my work"
        trigRef={projectsTriggerRef}
      />
      <Container width="90%" maxWidth={screen.md_2}>
        {/* <ul className="message-bubble" data-hover="Hover over one of me!">
          {projectsList}
        </ul> */}

        <div
          className="temp message-bubble"
          data-text="This section is under construction"
        ></div>

        <BgImg />
      </Container>
    </StyledProjects>
  );
};

export default Projects;
