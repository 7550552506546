import React from 'react';

// styles
import { StyledHeading } from './HeadingStyles';

const Heading = ({ children, font, hover }) => {
  return (
    <StyledHeading font={font} hover={hover}>
      {children}
    </StyledHeading>
  );
};

export default Heading;
