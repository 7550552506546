import styled from 'styled-components';
import {
  flexCenter,
  screen,
  messageBubble
} from '../../styles config/utilities';
import { StyledButton } from '../../components/Button/ButtonStyles';

export const StyledContact = styled.section`
  ${flexCenter('column')}
  align-items: unset;
  position: relative;
  padding-top: 5rem;

  /* push footer down on smaller screens */
  @media only screen and (max-width: ${screen.sm}) {
    margin-bottom: 2em;
  }

  p {
    line-height: 1.5;
    width: min(100%, ${screen.xs});
    position: relative;

    &:not(:last-of-type) {
      margin-bottom: var(--spacing);
    }
  }

  ul {
    ${flexCenter('row')}
    justify-content: flex-end;
    list-style-type: none;

    @media only screen and (max-width: ${screen.xs}) {
      justify-content: center;
    }
  }

  li {
    &:not(:last-of-type) {
      margin-right: 1em;
    }
  }

  /* handle email link weird spacing issue - instead of <br/> trick */
  a[type='email'] {
    white-space: nowrap;
  }
`;

export const Bar = styled.div`
  margin: 1em 0;
  width: 105%;
  height: 0.2em;
  transform: translateX(-2.5%);
  background-color: var(--textColor);
`;

export const ModalButton = styled(StyledButton)`
  font-weight: bold;
  position: relative;

  ${({ msgTop, msgLeft }) =>
    messageBubble('data-label', `${msgTop}`, `${msgLeft}`, false)}

  @media (hover: hover) {
    &:hover,
    &:focus {
      filter: drop-shadow(0.15rem 0 0 var(--accentRed));
      outline: none;

      .hover-underline {
        &::after {
          transform: scaleX(1);
        }
      }
    }
  }
`;
