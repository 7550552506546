import styled from 'styled-components';
import { screen, flexCenter } from '../../styles config/utilities';

export const StyledNav = styled.nav`
  ${flexCenter('column')}
  margin: 3em 0 auto;

  @media only screen and (min-width: ${screen.sm}) {
    flex-direction: row;
    margin: 0 0 0 auto;
  }
`;

export const NavLink = styled.a`
  margin: 0.75em 0;
  text-decoration: none;
  color: var(--textColor);
  position: relative;
  cursor: pointer;

  @media only screen and (min-width: ${screen.sm}) {
    margin: 0 calc(1vw + 2em) 0 0;
  }

  &:visited {
    color: currentColor;
  }

  @media (hover: hover) {
    &:hover,
    &:focus {
      filter: drop-shadow(0.15rem 0 0 var(--accentRed));

      .hover-underline {
        &::after {
          transform: scaleX(1);
        }
      }
    }
  }
`;
