import styled from 'styled-components';
import { screen } from '../../styles config/utilities';
import { black_2 } from '../../styles config/theme';

export const StyledHeading = styled.h2`
  font-size: clamp(5rem, 10vw, 8.5rem);
  font-family: var(--headingFont);
  font-weight: 700;
  color: var(--bgColor);
  letter-spacing: 2px;
  text-align: center;
  user-select: none;
  /* margin: 5rem 0; */
  margin: 0 0 5rem;
  position: relative;

  @media screen and (max-width: ${screen.md}) {
    padding: 0;
  }

  &::before {
    position: absolute;
    content: attr(data-text);
    font-size: clamp(3.8rem, 9.5vw, 100%);
    color: rgba(var(--headingColorRGB), 0.05);
    /* color: rgba(var(--accentRedRGB), 0.07); */

    /* color: var(--bgColor);
    text-shadow: 0 0 0.02em rgba(var(--headingColorRGB), 0.5); */

    /* text-shadow: 1px 1px rgba(var(--headingColorRGB), 0.5),
      -2px -2px rgba(var(--headingColorRGB), 0.5); */

    line-height: 1;
    text-align: center;
    white-space: nowrap;

    width: 100%;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -0.35em);
  }
`;

export const Label = styled.span`
  font-size: 0.5em;
  color: var(--bgColor);
  background-color: var(--headingColor);
  text-transform: uppercase;
  display: inline-block;
  transform: translateY(43%);
  padding: 0.2em 0.5em 0;
  border-radius: 3px;

  span {
    display: block;
    margin-bottom: -0.3em;
  }

  /* emoji */
  .emoji {
    fill: var(--accentYellow);
    position: absolute;
    width: 0.55em;
    height: 0.55em;
    top: -48.5%;
    transform: translateX(-50%);
    border-radius: 50%;
    animation: roll 5s ease-in-out infinite alternate;
    /* background-color: var(--textColor); */
    background-color: ${black_2};
  }

  @keyframes roll {
    0% {
      transform: translateX(-250%) rotate(-360deg);
    }

    50% {
      transform: translateX(-50%) rotate(0);
    }

    100% {
      transform: translateX(150%) rotate(360deg);
    }
  }
`;
