import React from 'react';

// styles
import { StyledFooter } from './FooterStyles';

// assets
import { ReactComponent as Logo } from '../../assets/logo/logo-footer.svg';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter>
      <p>&copy; {currentYear}.</p>

      <Logo />
    </StyledFooter>
  );
};

export default Footer;
