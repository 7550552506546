import React from 'react';

// components
import Navbar from '../Navbar';

// styles
import { StyledHeader, DarkModeButton } from './HeaderStyles';

// assets
import { ReactComponent as Logo } from '../../assets/logo/logo-header.svg';
import { ReactComponent as Sun } from '../../assets/icons/sun.svg';
import { ReactComponent as Moon } from '../../assets/icons/moon.svg';

const Header = ({ toggleTheme, darkMode, toggleNav, showOverlay }) => {
  const handleClick = () => {
    toggleTheme();
  };

  return (
    <StyledHeader show={showOverlay}>
      <a href="/" aria-label="Home">
        <Logo />
      </a>

      <Navbar hideNav={toggleNav} />

      <DarkModeButton onClick={handleClick} aria-label="Toggle dark mode">
        {darkMode ? <Moon /> : <Sun />}
      </DarkModeButton>
    </StyledHeader>
  );
};

export default Header;
