import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

// styles
import { Scroll, StyledHero } from './HeroStyles';

// assets
import { ReactComponent as BgImg } from '../../assets/logo/hero-bg.svg';

const Hero = () => {
  // animation using GSAP
  const bgImgRef = useRef();
  const el1 = useRef();
  const el2 = useRef();
  const el3 = useRef();
  const scrollRef = useRef();

  useEffect(() => {
    const els = [el1.current, el2.current, el3.current];

    gsap.from(bgImgRef.current, {
      y: -15,
      opacity: 0,
      ease: 'expo.inOut',
      duration: 2
    });
    gsap.from(els, {
      y: 30,
      ease: 'power3.easeOut',
      opacity: 0,
      delay: 1.75,
      stagger: 0.75
    });
    gsap.from(scrollRef.current, {
      y: -25,
      opacity: 0,
      ease: 'back.out(2)',
      delay: 3.75
    });
  }, []);

  return (
    <StyledHero id="home">
      <h1 ref={el1}>Hi, I'm Michelle.</h1>

      <p ref={el2}>I'm a developer with an appreciation for design.</p>
      <p ref={el3}>
        I don't just build websites. I build websites that are engaging and will
        give users a great experience.
      </p>

      <BgImg ref={bgImgRef} />
      <Scroll ref={scrollRef} />
    </StyledHero>
  );
};

export default Hero;
