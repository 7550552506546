import styled from 'styled-components';

export const StyledImage = styled.picture`
  img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 3px;
    border: 1px solid var(--bgColor);
    box-shadow: 0 0.5em 0.65em -0.4em rgba(var(--shadow), 0.3),
      0 1em 1.2em rgba(var(--shadow), 0.1);
  }
`;
