import styled, { css } from 'styled-components';
import { screen } from '../../styles config/utilities';

export const StyledHeading = styled.h3`
  font-size: clamp(2.8rem, 5vw, 3.5rem);
  font-family: ${({ font }) => (font ? font : 'var(--headingFont)')};
  position: relative;
  width: 100%;

  /* bigFont styles */
  ${({ font }) =>
    font &&
    css`
      font-size: clamp(3rem, 4.5vw, 5.25rem);
      /* 
      text-shadow: 0 0 0.1em var(--headingColor);
      text-shadow: 0 0 0.1em var(--headingColor), 0 0 0.1em var(--accentRed);

      color: ${({ hover }) => (hover ? 'var(--textColor)' : 'var(--bgColor)')};
       */

      /* transition: text-shadow 0.1s ease, color 0.2s ease-out 0.05s; */

      /* new test styles */
      /* position: relative; */

      /* vertical line */
      &::before {
        position: absolute;
        content: '';
        left: -4.5%;
        height: 150%;
        width: 3px;
        background-color: var(--accentRed);
        transform: scaleY(0);
        /* transform-origin: bottom; */
        transition: transform 0.2s ease-in-out;

        @media only screen and (max-width: ${screen.md}) {
          visibility: hidden;
        }
      }

      ${({ hover }) =>
        hover
          ? css`
              color: var(--headingColor);
              text-shadow: 0 0 0.1em var(--textColor);
              /* text-shadow: -0.05em 0.05em 0.5rem rgba(var(--accentRedRGB), 0.75),
                0 0 0.1em rgba(var(--headingColorRGB), 0.2); */

              color: var(--headingColor);
              text-shadow: 0 0 2px var(--textColor);

              transition: text-shadow 0.25s ease, color 0.25s ease-in-out 0.05s;

              &::before {
                transform: scaleY(1);
                transition: transform 0.2s ease-in-out;
              }
            `
          : css`
              /* color: rgba(var(--textColorRGB), 0.05); */
              /* text-shadow: 0 0 0.1em var(--textColor),
                0 0 0.1em var(--accentRed);
              text-shadow: 0 0 0.1em var(--accentRed); */

              transition: text-shadow 0.5s ease, color 0.25s ease-in-out 0.05s;
              transition: text-shadow 0.25s ease, color 0.25s ease-in-out 0.05s;

              color: var(--bgColor);
              text-shadow: 0 0 0.25em rgba(var(--shadow), 0.5);
              text-shadow: 0 0 0.02em rgba(var(--headingColorRGB), 0.5);

              text-shadow: 1px 1px rgba(var(--headingColorRGB), 0.5),
                -2px -2px rgba(var(--headingColorRGB), 0.5);
            `}

      @media only screen and (max-width: ${screen.md}) {
        /* color: var(--textColor); */
        /* text-shadow: 0 0 0.1em var(--headingColor); */

        color: var(--headingColor);
        text-shadow: none;
        /* text-shadow: 0 0 10px var(--textColor); */
        /* filter: drop-shadow(-0.05em -0.03em 0 var(--accentRed)); */
      }
    `}
`;
