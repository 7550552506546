import React from 'react';
import { StyledButton } from './ButtonStyles';

const Button = ({ children, handleClick, ariaLabel }) => {
  return (
    <StyledButton onClick={handleClick} aria-label={ariaLabel}>
      {children}
    </StyledButton>
  );
};

export default Button;
